import React from 'react';
import JssProvider from 'react-jss/lib/JssProvider';
import { createGenerateClassName } from '@mui/styles';
import MUIBaseline from '@mui/material/CssBaseline';
import Theme from './app/css/muiTheme';
import Config from './app/index.js';
import './app/css/bootstrap/app.scss';//app/css/bootstrap/app.scss
import { BrowserRouter } from "react-router-dom";
import 'devextreme/dist/css/dx.light.css';
import env from './envVariable.js'
// import "reactflow/dist/style.css";

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
});

const App = (props) => {
  return (
    <JssProvider generateClassName={generateClassName}>
      <Theme>
        <MUIBaseline />
        <BrowserRouter basename={env.REACT_APP_APP_ENV}>
          <Config />
        </BrowserRouter>
      </Theme>
    </JssProvider>
  );
}
export default App;