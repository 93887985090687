//local
// const env ={
//   REACT_APP_ENV:"uat",
//   REACT_APP_GOOGLE_CLIENT_ID:"447853881830-0de7o2dqg5c54crkadgtqsogve0e0trf.apps.googleusercontent.com",
//   REACT_APP_API_BASE_URL:"http://localhost:3001/nodeApi/v1",
//   // # REACT_APP_API_BASE_URL=https://uat-strategy-api.cardekho.com/nodeApi/v1
//   REACT_APP_BASIC_AUTH_USERNAME:"loanbox",
//   REACT_APP_BASIC_AUTH_PASSWORD:"fcf18e10-1599-11ea-a180-818ed3fed987",
//   // REACT_APP_API_GOOGLE_CLIENT_ID=187822835958-vrgtoss09asc7nplfho87gqg0mcb5pei.apps.googleusercontent.com
//   REACT_APP_API_GOOGLE_CLIENT_ID:"447853881830-0de7o2dqg5c54crkadgtqsogve0e0trf.apps.googleusercontent.com",
//   NODE_PATH:"src/"
// }

//uat
// const env = {
//   REACT_APP_ENV:"development",
//   REACT_APP_API_GOOGLE_CLIENT_ID:"417275107192-hbo2ga1fl9dau79vlsn6s88unc4tjtl8.apps.googleusercontent.com",
//   REACT_APP_API_BASE_URL:"https://strategybox-api.rupyy.in/nodeApi/v1",
//   REACT_APP_BASIC_AUTH_USERNAME:"loanbox",
//   REACT_APP_BASIC_AUTH_PASSWORD:"fcf18e10-1599-11ea-a180-818ed3fed987",
//   NODE_PATH:"src/"
// };

//production
const env = {
  REACT_APP_ENV:"development",
  REACT_APP_API_GOOGLE_CLIENT_ID:"417275107192-hbo2ga1fl9dau79vlsn6s88unc4tjtl8.apps.googleusercontent.com",
  REACT_APP_API_BASE_URL:"https://strategybox-api.rupyy.in/nodeApi/v1",
  REACT_APP_BASIC_AUTH_USERNAME:"loanbox",
  REACT_APP_BASIC_AUTH_PASSWORD:"fcf18e10-1599-11ea-a180-818ed3fed987",
  NODE_PATH:"src/",
  PORT:3000
};

if (process.env.REACT_APP_ENV === "prod") {
  env.REACT_APP_API_BASE_URL = "https://strategybox-api.rupyy.com/nodeApi/v1"
} 

export default env;
