import React, { Suspense } from "react";
import LoaderError, { ErrorBoundary } from "./components/common/loaderError";
import { SnackbarProvider } from "notistack";
const Config = React.lazy(() => import("../app/config"));

const App = props => {
  return (
    <ErrorBoundary className="min-vh-100">
      <Suspense fallback={<LoaderError className="min-vh-100" />}>
        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          maxSnack={3}>
          <Config {...props} />
        </SnackbarProvider>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
