const initialState = {
  client_id: '',
  associate_client_id: '',
  vertical_client_id: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_CLIENT_ID':
      return {
        ...state,
        client_id: action.client_id
      }
    case 'UPDATE_ASSOCIATE_CLIENT_ID':
      return {
        ...state,
        associate_client_id: action.client_id
      }
    case 'UPDATE_VERTICAL_CLIENT_ID':
      return {
        ...state,
        vertical_client_id: action.client_id
      }
    default:
      break;
  }
  return state;
};

export default reducer;